/*Colors: #1fea00, #27a102, #1c6000, #002f06, #000000*/
/*New colors: Light green: #45e700, brighter #41d302, dark green #237500*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'verdana';
    src: local('verdana'), url(./fonts/verdana.ttf) format('ttf');
    color: #FFFFFF;
}

header {
    height: 58px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #212529;
}

.container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: relative;
    width: 100%;
}

.container img {
    border-radius: 10px;
}

@media screen and (max-width: 480px) {
    .container img {
        top: 0;
    }
}

img .gamedisplay {
    border-radius: 10px;
}

.gamedisplay-img-overlay {
    display: flex; 
    justify-content: center;
}

.gamedisplay-img-back {
    z-index: 1;
}

.gamedisplay-img-top {
    position: absolute; 
    z-index: 2;
    top: 52.5%;
}

.backgroundImage {
    flex: 1;
    position: 'absolute';
    resize: 'cover';
    width: viewportWidth;
    height: viewportHeight;
    background-color: 'transparent';
    justify-content: 'center';
    align-items: 'center';
}

.nav-area { 
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    height: 58px;
}

.menus {
    list-style: none;
}

.menu-items {
    position: relative;
    font-size: 14px;
}

.menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.menu-items button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
}

.menu-items-fill {
    width: 30vh;
}

.menu-items a,
.menu-items button {
    text-align: left;
    padding: 0.3rem 0.5rem;
}

.menu-items a:hover,
.menu-items button:hover {
    background-color: #237500;
}

.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.dropdown {
    position: absolute;
    right: auto;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #41d302;
    border-radius: 0.5rem;
    display: none;
}

.dropdown.show {
    display: block;
}

.dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}

.ul-classic {
    display: block; 
    list-style-type: disc;
    margin-top: 1em; 
    margin-bottom: 1 em; 
    margin-left: 0; 
    margin-right: 0; 
    padding-left: 40px;
}

.li-classic {
    display: list-item;
    margin-left: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.default-text {
    color: #FFFFFF;
}

.footer {
    height: "700px";
    background-color: #237500;
}

.footer-bottom {
    background-color: #071700;
}

.footer-nav {
    background-color: #0c2800;
}

.footer-item .footer-link {
    color: #FFF9E2;
    text-decoration: none;
}

.footer-item .footer-link:hover {
    color: #FDD946;
    text-decoration: inherit;
}

.navbar-custom-top {
    background-color: #41d302;
}

.navbar-custom-nav {
    background-color: #41d302;
}

.navbar-custom-item {
    color: #FFF9E2;
}

.navbar-custom-item .navbar-custom-link:hover {
    color: #FDD946;
    text-decoration: inherit;
}

.navbar-nav .navbar-item.active .nav-link,
.navbar-nav .navbar-item:hover .nav-link {
    margin: "0px";
    padding: "0px";
}

.navbar-nav .navbar-item.active .nav-link,
.navbar-nav .navbar-item .nav-link {
    color: #FFF9E2;
}

.navbar-nav .navbar-item.active .nav-link,
.navbar-nav .navbar-item .active .nav-link {
    color: #0C6B68;
}

.navbar-nav .navbar-item.active .nav-link,
.navbar-nav .navbar-item:hover .nav-link {
    color: #FDD946;
}

.remove-button { 
    outline: none;
    padding: 5px; 
    border: 0px; 
    box-sizing: none; 
    background-color: transparent; 
}

.remove-button:focus,
.remove-button:hover {
    text-decoration: underline;
    background-color: transparent;
}

.remove-button,
.remove-button:active {
    background-color: transparent;
}